import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { startOfWeek, lastDayOfWeek } from "date-fns";

import {
  copyTextToClipboard,
  getClasses,
  getEventFullAddress,
  getFormattedTime,
} from "../../utils";
import { fetchEvents } from "../../services/events";
import { eventTypes } from "../../ts/consts";
import { RootState } from "../../store";
import Event from "../../ts/models/Event";
import PanelContainer from "../../components/Containers/PanelContainer";
import PanelInnerContainer from "../../components/Containers/PanelInnerContainer";
import WeekCalendar from "../../components/WeekCalendar/WeekCalendar";
import classes from "./EventCalendar.module.css";
import SelectPOD from "../../components/SelectPOD/SelectPOD";
import PanelActionContainer from "../../components/Containers/PanelActionContainer";
import Card from "../../components/Card/Card";
import Status from "../../components/Status/Status";
import timeIcon from "../../assets/icons/time.svg";
import locationIcon from "../../assets/icons/location.svg";
import TabBar from "../../components/TabBar/TabBar";
import copyIcon from "../../assets/icons/copy.svg";

export const EventCalendar = () => {
  const [podId, setPODId] = useState<string>("");
  const [events, setEvents] = useState<Event[]>([]);
  const [isHome, setIsHome] = useState(false);

  const fetchEventsData = async (weekStart?: Date) => {
    if (podId) {
      const filter: any = {
        offset: 0,
        where: {
          podId,
          startDate: (
            weekStart || startOfWeek(new Date(), { weekStartsOn: 1 })
          ).valueOf(),
          endDate: lastDayOfWeek(weekStart || new Date(), {
            weekStartsOn: 1,
          }).valueOf(),
          isActive: true,
        },
      };
      if (isHome) {
        filter.where.isHome = true;
      }
      try {
        const res = await fetchEvents(filter);
        if (res.status) {
          setEvents(res.data);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    fetchEventsData();
  }, [podId, isHome]);

  const renderEventItem = (eventItem: Event, date: Date) => {
    return <EventItem event={eventItem} podId={podId} date={date} />;
  };

  const nextPrevHandler = (weekStart: Date) => {
    fetchEventsData(weekStart);
  };

  const tabChangeHandler = (isHome: string) => {
    setIsHome(!!isHome);
  };

  return (
    <PanelContainer name='Event Calendar'>
      <PanelActionContainer
        leftChildren={
          <SelectPOD
            value={podId}
            onChange={(e) => {
              setPODId(e.target.value);
            }}
          />
        }
      />
      <PanelInnerContainer>
        <Card className={classes.main}>
          <TabBar
            tabs={eventTypes}
            idExtractor='id'
            onTabChange={tabChangeHandler}
          />
          <WeekCalendar
            calendarItems={events}
            renderCalendarItem={renderEventItem}
            onPrevWeek={nextPrevHandler}
            onNextWeek={nextPrevHandler}
            disablePrev={!podId}
            disableNext={!podId}
          />
        </Card>
      </PanelInnerContainer>
    </PanelContainer>
  );
};

type Props = {
  event: Event;
  podId: string;
  date: Date;
};

const EventItem: React.FC<Props> = ({ event, podId, date }) => {
  const servicesMaster = useSelector(
    (state: RootState) => state.masters.services
  );
  const resourceName = useSelector(
    (state: RootState) => state.auth.resourceName
  );

  const url = window.location.href;
  const domainName = url.substring(0, url.lastIndexOf(`/${resourceName}`));

  const getEventDay = () => {
    const selectedTime = new Date(date).getTime();
    var numMillisInDay = 24 * 60 * 60 * 1000;
    var numDays = (selectedTime - event.startTime) / numMillisInDay;
    return Math.ceil(numDays) + 1;
  };

  const { name, startTime, endTime, services } = event;

  const eventDay = getEventDay();
  const eventDayParam = eventDay > 1 ? `?day=${eventDay}` : "";
  const productSelectionLink = `${domainName}/registration/${resourceName}/pod/${podId}/event/${event.id}/product-selection${eventDayParam}`;

  const eventSelectHandler = () => {};
  const serviceNames = services.map((serviceWithId) => {
    const service = servicesMaster.find(
      (service) => service.id === serviceWithId.serviceId
    );

    if (service) {
      return service.name;
    } else {
      return "";
    }
  });
  return (
    <div className={classes.eventItem} onClick={eventSelectHandler}>
      <div className={classes.basicInfo}>
        <p className={classes.eventName}>{name}</p>
        <CopyButton copyText={productSelectionLink} tipText='Copy link' />
      </div>
      <div className={classes.timeAndLocationInfo}>
        <div className={classes.time}>
          <img src={timeIcon} alt='Time' style={{ marginRight: "0.5rem" }} />
          <div>
            <span style={{ display: "block" }}>{`${getFormattedTime(
              startTime
            )} -`}</span>
            <span>{`${getFormattedTime(endTime)}`}</span>
          </div>
        </div>
        <div className={classes.location}>
          <img
            src={locationIcon}
            alt='Location'
            style={{ marginRight: "0.5rem" }}
          />
          <div>{getEventFullAddress(event)}</div>
        </div>
      </div>
      <div className={classes.medServices}>
        {serviceNames.map((serviceName, idx) => (
          <Status name={serviceName} key={idx} />
        ))}
      </div>
    </div>
  );
};

export default EventCalendar;

type CopyProps = {
  copyText: string;
  tipText?: string;
};

const CopyButton: React.FC<CopyProps> = ({ copyText, tipText = "Copy" }) => {
  const [copied, setCopied] = useState(false);

  const copyHandler = () => {
    copyTextToClipboard(copyText).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    });
  };

  return (
    <div
      className={getClasses(
        classes.copyContainer,
        copied ? classes.copiedContainer : ""
      )}>
      <img
        className={classes.copyIcon}
        src={copyIcon}
        alt={tipText}
        title={tipText}
        onClick={copyHandler}
      />
    </div>
  );
};

import { useState, PropsWithChildren, ReactElement } from "react";

import { getClasses } from "../../utils";
import {
  format,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks,
  getYear,
} from "date-fns";
import classes from "./WeekCalendar.module.css";
import arrowLeftIcon from "../../assets/icons/arrow-left-circle.svg";
import arrowRightIcon from "../../assets/icons/arrow-right-circle.svg";

const getDate = (date: Date) => {
  return new Date(date.toDateString());
};

interface Dates {
  id: string;
  startTime: number;
  endTime: number;
}

type Props<T> = {
  disablePrev?: boolean;
  disableNext?: boolean;
  calendarItems: T[];
  renderCalendarItem: (item: T, day: Date) => JSX.Element;
  onPrevWeek: (weekStart: Date) => void;
  onNextWeek: (weekStart: Date) => void;
  onItemSelect?: (item: T, day: Date) => void;
};

const WeekCalendar = <T extends Dates>({
  disablePrev,
  disableNext,
  calendarItems,
  renderCalendarItem,
  onPrevWeek,
  onNextWeek,
  onItemSelect,
}: PropsWithChildren<Props<T>>): ReactElement | null => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));

  const changeWeekHandler = (btnType: string) => {
    if (btnType === "prev") {
      onPrevWeek(startOfWeek(subWeeks(currentMonth, 1), { weekStartsOn: 1 }));

      setCurrentMonth(subWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
    } else if (btnType === "next") {
      onNextWeek(startOfWeek(addWeeks(currentMonth, 1), { weekStartsOn: 1 }));

      setCurrentMonth(addWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
    }
  };

  const onItemClickHandler = (item: T, day: Date) => {
    onItemSelect && onItemSelect(item, day);
  };

  const renderHeader = () => {
    return (
      <div className={getClasses(classes.header, classes.row)}>
        <span className={classes.week}>{`Week ${currentWeek}, ${getYear(
          currentMonth
        )}`}</span>
        <img
          src={arrowLeftIcon}
          alt='Goto previous week'
          className={getClasses(
            classes.arrowIcon,
            disablePrev ? classes.disabledIcon : ""
          )}
          draggable='false'
          onClick={() => disablePrev || changeWeekHandler("prev")}
        />
        <img
          src={arrowRightIcon}
          alt='Goto next week'
          className={getClasses(
            classes.arrowIcon,
            disableNext ? classes.disabledIcon : ""
          )}
          draggable='false'
          onClick={() => disableNext || changeWeekHandler("next")}
        />
      </div>
    );
  };

  const renderBody = () => {
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    let days: JSX.Element[] = [];
    let day = startDate;

    for (let i = 0; i < 7; i++) {
      let cloneDay = day; // created for closure
      days.push(
        <div className={classes.dayOfWeek} key={`weekday-${i}`}>
          <div
            className={getClasses(
              classes.dayHeader,
              isSameDay(day, new Date()) ? classes.today : ""
            )}>
            <div className={classes.day}>{format(day, "EEE")}</div>
            <div className={classes.date}>{format(day, "dd MMM")}</div>
          </div>
          <div
            className={getClasses(
              ...[
                classes.dayItems,
                classes.cell,
                `${isSameDay(day, new Date()) ? classes.today : ""}`,
              ]
            )}
            style={{ cursor: onItemSelect ? "pointer" : "auto" }}>
            {calendarItems
              .filter((item) => {
                return (
                  cloneDay >= getDate(new Date(item.startTime)) &&
                  cloneDay <= getDate(new Date(item.endTime))
                );
              })
              .map((item) => {
                return (
                  <div
                    onClick={() => onItemClickHandler(item, cloneDay)}
                    key={`item-${item.id}-${cloneDay.getTime()}`}>
                    {renderCalendarItem(item, cloneDay)}
                  </div>
                );
              })}
          </div>
        </div>
      );
      day = addDays(day, 1);
    }

    return (
      <div className={classes.body}>
        <div className={classes.row}>{days}</div>
      </div>
    );
  };

  return (
    <div className={classes.calendar}>
      {renderHeader()}
      {renderBody()}
    </div>
  );
};

export default WeekCalendar;
